import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
// import Footer from "components/footers/FiveColumnWithInputForm.js";
import MiniCentered from "components/footers/MiniCenteredFooter";
import MainFeature1 from "components/features/TwoColWithButton.js";
import { LogoLink } from "components/headers/light.js";
import pgLogo from "images/demo/PG_Logo.svg";
// import MainFeature2 from "components/features/TwoColSingleFeatureWithStats.js";
// import MainFeature3 from "components/features/TwoColSingleFeatureWithStats2.js";
import Features from "components/features/ThreeColSimple.js";
// import Features from "components/features/ThreeColWithSideImage.js";
import TeamCardGrid from "components/cards/ProfileThreeColGrid.js";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomerLoveIconImage from "images/simple-icon.svg";

const Subheading = tw.span`uppercase tracking-wider text-sm`;

const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-black px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;

export default () => {
  return (
    <AnimationRevealPage>
      {/* <Header /> */}
      <NavRow>
            <LogoLink href="/">
              <img src={pgLogo} alt="" width="150" />
              {/* Print Genius */}
            </LogoLink>
            <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
              <NavLink target="_self" href="/about">
                About
              </NavLink>
              <NavLink target="_self" href="/pricing">
                Pricing
              </NavLink>
              <NavLink target="_blank" href="mailto:printgenius.app@gmail.com">
                Contact
              </NavLink>
              <div tw="md:hidden flex-100 h-0"></div>
              <PrimaryNavLink target="_blank" href="https://app.printgenius.io">
                Get Started
              </PrimaryNavLink>
            </div>
        </NavRow>
      <MainFeature1
        subheading={<Subheading>About Print Genius</Subheading>}
        heading="Making 3D printing more accessible"
        description="We're Paul and Nosa — friends working to make 3D printing easy for everyone. We met as mechanical engineering students at Stanford where we also taught 3D printing workshops to low-income students. 
        Nosa later earned his PhD from the University of Michigan where his research helped to increase the speed and accuracy of FDM 3D printers.
        Paul helped design 3D printed prosthetic hands in South America before working as a data analyst and product manager.
        We started Print Genius because we've seen the positive impact 3D printing can have on communities around the world when the technology is made accessible."
        // description="We're a team of engineers and PhD researchers from Stanford and the University of Michigan passionate about making 3D printing easy for everyone. "
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="mailto:printgenius.app@gmail.com"
        imageSrc="/about_us1.png"
        // imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Anyone with a 3D printer can make an impact"
        description="Our goal is for anyone with a 3D printer to easily launch their own custom 3D printing shop and for any customer to have a pleasant, simple, and informative experience. We automate tasks like quoting and part orientation so 3D printing shops can deliver more value by educating their customers and printing beautiful products!"
        buttonRounded={false}
        primaryButtonText="Get Started"
        primaryButtonUrl = "https://app.printgenius.io"
        imageSrc="/about_us2.png"
        // imageSrc="https://images.unsplash.com/3/doctype-hi-res.jpg?ixlib=rb-1.2.1&auto=format&fit=crop&w=768&q=80"
        textOnLeft={false}
      />
      {/* <Features
        subheading={<Subheading>Our Values</Subheading>}
        heading="We follow these."
        description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        cards={[
          {
            imageSrc: SupportIconImage,
            title: "24/7 Support",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: ShieldIconImage,
            title: "Strong Teams",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
          {
            imageSrc: CustomerLoveIconImage,
            title: "Customer Satisfaction",
            description: "Lorem ipsum donor amet siti ceali placeholder text alipiscing elit sed do eiusmod temport"
          },
        ]}
        linkText=""
      /> */}
      {/* <TeamCardGrid 
        subheading={<Subheading>Our Team</Subheading>}
      /> */}
      <MiniCentered />
    </AnimationRevealPage>
  );
};
