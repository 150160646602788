import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro";
import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";

import defaultCardImage from "images/shield-icon.svg";

import { ReactComponent as SvgDecoratorBlob3 } from "images/svg-decorator-blob-3.svg";

import SupportIconImage from "images/support-icon.svg";
import ShieldIconImage from "images/shield-icon.svg";
import CustomizeIconImage from "images/customize-icon.svg";
import FastIconImage from "images/fast-icon.svg";
import ReliableIconImage from "images/reliable-icon.svg";
import SimpleIconImage from "images/simple-icon.svg";
import StoreImage from "images/store.svg";
import AutoquoterImage from "images/quoter.svg";
import SettingsImage from "images/settings.svg";
import InboxImage from "images/inbox-icon.svg";
import FormImage from "images/form-icon.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4`;
const Heading = tw(SectionHeading)`w-full text-black`;
const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-2xl leading-none text-black`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;

const DecoratorBlob = styled(SvgDecoratorBlob3)`
  ${tw`pointer-events-none absolute right-0 bottom-0 w-64 opacity-25 transform translate-x-32 translate-y-48 `}
`;

export default ({ cards = null, heading = "Designed for Your 3D Printing Needs", subheading = "Features", description = "Whether you're a professional 3D printing service or an enthusiast looking to monetize your hobby, our platform makes it easy to launch or manage your 3D printing business" }) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) imageSrc - the image shown at the top of the card
   *  2) title - the title of the card
   *  3) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { 
      imageSrc: FormImage, 
      title: "Forms",
      description: "Customize a form to intake print orders, design orders, and general inquiries and embed it on your website using iframe code"
    },
    {
      imageSrc: InboxImage,
      title: "Message Inbox",
      description: "Manage all the form entries and messages with customers in an all-in-one messaging inbox. Customers can respond directly from their email provider"
    },
    // {
    //   imageSrc: SettingsImage,
    //   title: "Custom Settings",
    //   description: "Add your FDM printers and customize printer settings, material costs, build costs, and more. We also calculate the cost of support material so you don't have to"
    // },
    { 
      imageSrc: StoreImage, 
      title: "Custom Store",
      description: "Activate and share your personal storefront link in minutes to accept orders. Seamless payment integration allows customers to pay instantly and securely" 
    },
    { 
      imageSrc: AutoquoterImage, 
      title: "Autoquoter",
      description: "Our automatic quoting algorithm takes the guesswork out of pricing your FDM prints, ensuring that your customers always receive a fair and accurate price"
    },
    // { 
    //   imageSrc: ShieldIconImage, 
    //   title: "Secure Library",
    //   description: "Preview and quote any STL files in the admin autoquoter and manage your 3D files securely in the Print Genius cloud library" 
    // },
    { 
      imageSrc: SimpleIconImage, 
      title: "Manage Orders",
      description: "Provide pre-configured confirmation and tracking pages to your customers and manage orders from your customers" 
    },
    { 
      imageSrc: ShieldIconImage, 
      title: "Invoices & Quotes",
      description: "Generate invoice and quote PDFs to send to customers which they can use to approve the quote and complete their order" 
    },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {description && <Description>{description}</Description>}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="imageContainer">
                <img src={card.imageSrc || defaultCardImage} alt="" />
              </span>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob /> */}
    </Container>
  );
};
