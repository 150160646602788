import React from "react";
import tw from "twin.macro";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import Pricing from "components/pricing/TwoPlansWithDurationSwitcher.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import MiniCentered from "components/footers/MiniCenteredFooter";
import FAQ from "components/faqs/SingleCol.js";

import { LogoLink } from "components/headers/light.js";
import pgLogo from "images/demo/PG_Logo.svg";

const Row = tw.div`flex`;
const NavRow = tw(Row)`flex flex-col lg:flex-row items-center justify-between`;
const NavLink = tw.a`mt-4 lg:mt-0 transition duration-300 font-medium pb-1 border-b-2 mr-12 text-gray-700 border-gray-400 hocus:border-gray-700`;
const PrimaryNavLink = tw(
  NavLink
)`text-gray-100 bg-black px-6 py-3 border-none rounded hocus:bg-primary-900 focus:shadow-outline mt-6 md:mt-4 lg:mt-0`;

export default () => {
  return (
    <AnimationRevealPage>
      <NavRow>
            <LogoLink href="/">
              <img src={pgLogo} alt="" width="150" />
              {/* Print Genius */}
            </LogoLink>
            <div tw="flex flex-wrap justify-center lg:justify-end items-center -mr-12">
              <NavLink target="_self" href="/about">
                About
              </NavLink>
              <NavLink target="_self" href="/pricing">
                Pricing
              </NavLink>
              <NavLink target="_blank" href="mailto:printgenius.app@gmail.com">
                Contact
              </NavLink>
              {/* <PrimaryNavLink target="_blank" href="https://app.printgenius.io">
                Get Started
              </PrimaryNavLink> */}
              <div tw="md:hidden flex-100 h-0"></div>
            </div>
      </NavRow>
      <Pricing />
      {/* <Testimonial
        heading="Our Paying Customers"
      />
      <FAQ /> */}
      <MiniCentered/>
    </AnimationRevealPage>
  );
};
